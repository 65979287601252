import React from 'react'
import Layout from '../components/Layout'
import SEO from '../components/seo'
import Image from '../components/Image'
import SocialLink from '../components/SocialLink'
import ExternalLink from '../components/ExternalLink'
import { AboutSection, BioText, Columns, SocialLinks } from '../components/About.styles'

const AboutPage = () => {
  return (
      <Layout>
        <SEO title="About" />
        <AboutSection id="about">
          <Columns className="columns">
            <div className="column">
              <Image />
            </div>
            <BioText className="column bio-text">
              <p>
                My passion lies at the intersection of design and development. I spend my days solving problems with
                code and creating seamless, user-centric interfaces.
                When I'm not at my desk, you can find me playing tourist, wandering around art museums or studying in
                cute cafes.
                I'm currently working as a Lead Front-End Developer (and sometimes Designer) over at <ExternalLink
                  externalUrl="https://www.cloudraker.com/"
                  linkText=" CloudRaker"
              />.
              </p>
              <br />
              <p>You can also find me on:</p>
              <SocialLinks>
                <SocialLink
                    platform="Github"
                    externalUrl="https://github.com/ninayujiri"
                />
                <SocialLink
                    platform="Instagram"
                    externalUrl="https://instagram.com/ninayujiri"
                />
                <SocialLink
                    platform="LinkedIn"
                    externalUrl="https://www.linkedin.com/in/ninayujiri/"
                />
              </SocialLinks>
            </BioText>
          </Columns>
        </AboutSection>
      </Layout>
  )
};

export default AboutPage
