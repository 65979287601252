import styled from '@emotion/styled'

export const AboutSection = styled.section`
  max-width: 1100px;
  min-height: 80vh;
`;

export const Columns = styled.div`
  align-self: center;
  align-items: center;
  width: 100%;
`;

export const BioText = styled.div` 
 padding: 0 0 0 3em;
`;

export const SocialLinks = styled.div`
  margin-top: .5em;
`;
